<template>
  <div class="main border-gray has-bottom" v-if="!oldPhone">
    <van-nav-bar class="top-bar" title="绑定手机" left-arrow @click-left="onClickLeft"/>
    <van-form class="border-form" ref="form">
      <van-cell-group>
        <van-field v-model="user.phone" :rules="userFormRules.phone" name="phone" clearable placeholder="请输入11位手机号"/>
        <van-field center clearable placeholder="请输入短信验证码" v-model="user.sms_code">
          <template #button>
            <van-button size="small" plain type="primary" @click="sendValidateCode('New')" :disabled="isDisabled"
                        class="plain-field-btn">
              {{ isDisabled ? `${count}s后重新获取` : "获取验证码" }}
            </van-button>
          </template>
        </van-field>
      </van-cell-group>
      <div class="form-bottom">
        <van-button type="primary" block @click="onSubmit">确定</van-button>
      </div>
    </van-form>
  </div>
  <div class="main border-gray has-bottom" v-else>
    <van-nav-bar class="top-bar" title="换绑手机" left-arrow @click-left="onClickLeft"/>
    <van-form class="border-form" ref="form">
      <van-cell-group>
        <van-field v-model="user.old_phone" :rules="userFormRules.phone" name="old_phone" clearable placeholder="请输入原手机号"/>
        <van-field center clearable placeholder="请输入验证码" v-model="user.old_sms_code">
          <template #button>
            <van-button size="small" plain type="primary" @click="sendValidateCode('Old')" :disabled="oldIsDisabled"
                        class="plain-field-btn">
              {{ oldIsDisabled ? `${oldCount}s后重新获取` : "获取验证码" }}
            </van-button>
          </template>
        </van-field>
        <van-field v-model="user.phone" :rules="userFormRules.phone" name="phone" clearable placeholder="请输入新手机号"/>
        <van-field center clearable placeholder="请输入验证码" v-model="user.sms_code">
          <template #button>
            <van-button size="small" plain type="primary" @click="sendValidateCode('New')" :disabled="isDisabled"
                        class="plain-field-btn">
              {{ isDisabled ? `${count}s后重新获取` : "获取验证码" }}
            </van-button>
          </template>
        </van-field>
      </van-cell-group>
      <div class="form-bottom">
        <van-button type="primary" block @click="onSubmit">确定</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import {Field, CellGroup, NavBar, Button, Form, Toast} from 'vant';
import {reactive, ref,computed} from 'vue';
import {changeMobile} from "@/api/user";
import {sendSmsChangeMobile} from "@/api/verification";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import "@/style/common.css"
import {onClickLeft} from "@/utils/function"
export default {
  name: "BindPhone",
  components: {
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [NavBar.name]: NavBar,
    [Button.name]: Button,
    [Form.name]: Form,
  },
  setup: function () {
    //初始化
    const user = reactive({
      old_phone: '',
      old_sms_code: '',
      phone: '',
      sms_code: ''
    });
    const store = useStore();
    const oldPhone = computed(() => {
      return store.state.userInfo.phone
    })

    const form = ref();
    //倒计时
    const oldCount = ref()
    const count = ref()
    const oldIsDisabled = ref(false);
    const isDisabled = ref(false);
    //发送验证码
    const sendValidateCode = (type) => {
      const validateName = type == 'New' ? 'phone' : 'old_phone'
      form.value.validate(validateName).then(() => {
        Toast.loading({
          message: "发送中...",
          forbidClick: true,
        });
        const sendData = {
          phone: user[validateName],
          type: type
        }
        return sendSmsChangeMobile(sendData)
      }).then((res) => {
        Toast(res.message);
        if (type == 'New') {
          count.value = 60;
          isDisabled.value = true;
          let timer = setInterval(() => {
            if (count.value > 0) {
              count.value--;
            } else {
              isDisabled.value = false;
              clearInterval(timer);
            }
          }, 1000)
        } else {
          oldCount.value = 60;
          oldIsDisabled.value = true;
          let timer = setInterval(() => {
            if (oldCount.value > 0) {
              oldCount.value--;
            } else {
              oldIsDisabled.value = false;
              clearInterval(timer);
            }
          }, 1000)
        }

      }).catch((res) => {
        Toast(res.message);
      });
    }
    //校验规则
    const userFormRules = {
      phone: [
        {required: true, message: "请输入手机号"},
        {
          pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
          message: "手机号格式错误"
        },
      ],
    };
    const router = useRouter();
    //表单提交
    const onSubmit = () => {
      Toast.loading({
        message: "提交中...",
        forbidClick: true,
      });
      changeMobile(user).then((res) => {
        Toast(res.message);
        setTimeout(()=>{
          store.commit('updateUserInfo')
          router.replace('/personal/person')
        },2000)
      })
    };

    return {
      user,
      form,
      count,
      oldCount,
      isDisabled,
      oldIsDisabled,
      userFormRules,
      oldPhone,

      onClickLeft,
      sendValidateCode,
      onSubmit
    };
  },
}
</script>

<style scoped>
</style>
